import React, {useState, useEffect } from 'react'
import axios from "axios";
import config from 'AppConfig';

export const AppContext = React.createContext();
export const AppContextProvider = ({children}) => {

    const [context, setContext] = useState({ initialized: false, identity: null});
    useEffect(() => {
        console.log("APP CONTEXT: CHECKING FOR USER IDENTITY")
        // Configure Axios so that it always sends cookies when
        // invoking APIs on the server:
        axios.defaults.withCredentials = true;
        // Attempt to retrieve the user's identity by pinging the
        // session endpoint on the server. The server will check the
        // request for the TMA cookie and then return the user's identity
        // information if the cookie is valid.
        const init = async () => {
            try {
                console.log("APP CONTEXT: MAKING REQUEST")
                const response = await axios.get(config.api.base + "/ident");
                if(response.status != 200) {
                    console.log("APP CONTEXT: Identity error")
                    setContext({ initialized: true, identity: null})
                } else {
                    console.log("APP CONTEXT: USER IDENTITY WAS RETURNED FROM SERVER")
                    setContext({ initialized: true, identity: response.data})
                }
            } catch(x) {
                console.log("APP CONTEXT: Identity exception")
                setContext({ initialized: true, identity: false});
            }
        }
        init();
    }, [])

    function setIdentity(identity) {
        context.identity = identity;
        setContext({...context});
    }

    const values = { config, identity: context.identity, setIdentity: (i) => { setIdentity(i) }}
    if(context.initialized === false) {
        console.log("APP CONTEXT NOT INITIALIZED YET")
        return null;
    } else {
        console.log("APP CONTEXT NOW RETURNING THE PAGE:")
        return (
            <AppContext.Provider value={values}>{children}</AppContext.Provider>
        );
    }

}
