import React, {useState, useContext, useEffect} from "react";
import Dashview from "../Dashview";
import axios from 'axios';
import {AppContext} from "AppContext";

const Content = (props) => {
    
    const { identity, config } = useContext(AppContext)
    const [account, set_account] = useState(null);

    // Retrieve relevant records for this account:
    useEffect(() => {
        const action = async () => {
            const account_api_response = await axios.get(config.api.base + '/admin/accounts/' + identity.userAcctId);
            set_account(account_api_response.data[0]);
            console.log("fired");
        }
        action();
    }, [config.api.base, identity.userAcctId])

    function onChangeFirstName(args) {
        account.first_name = args.target.value;
        set_account({...account})
    }

    function onChangeLastName(args) {
        account.last_name = args.target.value;
        set_account({...account})
    }

    function onChangeCompany(args) {
        account.company = args.target.value;
        set_account({ ...account})
    }

    function onChangePhone(args) {
        account.phone = args.target.value;
        set_account({...account})
    }

    function onSubmit(args) {
        args.preventDefault();
        const action = async () => {
            try {
                const response = await axios.put(config.api.base + '/admin/accounts/' + identity.userAcctId, account);
                console.log(response);
            } catch (x) {

            }
        }
        action();
    }

    function on_click_testing(args) {
        console.log(identity);
        console.log(account);
    }

    if(account === null) {
        return null;
    }

    return (
        <div className={"container mx-auto"}>
            <div className={"border-b-2 border-solid py-4"}>
                <div>
                    <span className={"text-3xl"}>Account Information</span>
                </div>
            </div>
            { false && // pr-4 border-r-2 border-dashed
            <div>
                <button onClick={on_click_testing}>Testing</button>
            </div>
            }
            <form className="mt-8" onSubmit={onSubmit}>
                <div className="flex">
                    <div className="flex flex-col justify-start items-start w-2/4 space-y-4">
                        <div className={"flex w-full"}>
                            <div className={"w-full"}>
                                <label htmlFor="email">Email / Username</label>
                                <input id="email" className="standardInput" type="text" placeholder="Company" 
                                        value={account.email} disabled 
                                />
                            </div>
                        </div>
                        <div className={"flex w-full space-x-8"}>
                            <div className={"w-full"}>
                                <label htmlFor="first-name">First name</label>
                                <input id={"first-name"} className="standardInput" type="text" placeholder="First Name" 
                                        value={account.first_name} onChange={onChangeFirstName}     
                                />
                            </div>
                            <div className={"w-full"}>
                                <label htmlFor="last-name">Last name</label>
                                <input id={"last-name"} className="standardInput" type="text" placeholder="Last Name" 
                                        value={account.last_name} onChange={onChangeLastName}
                                />
                            </div>
                        </div>
                        <div className={"flex w-full"}>
                            <div className={"w-full"}>
                                <label htmlFor="company">Company</label>
                                <input id="company" className="standardInput" type="text" placeholder="Company" 
                                        value={account.company} onChange={onChangeCompany}     
                                />
                            </div>
                        </div>
                        <div className={"flex w-full"}>
                            <div className={"w-full"}>
                                <label htmlFor="phone">Phone Number</label>
                                <input id="phone" className="standardInput" type="text" placeholder="Phone Number" 
                                        value={account.phone} onChange={onChangePhone}     
                                />
                            </div>
                        </div>
                        <div className={"flex w-full pt-4"}>
                            <button type={"submit"} className="mr-4 py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                SAVE 
                            </button>
                        </div>
                    </div>
                    { false &&
                    <div className="flex w-2/4 space-x-6 pl-4">
                        
                    </div>
                    }
                </div>
            </form>
        </div>
    )
}

const Account = (props) => {
    return (<Dashview {...props}><Content/></Dashview>);
}

export default Account;