import React from 'react';
import Dashview from 'paths/admin/dashboard/Dashview';

const Content = (props) => {
    return(
        <div className={"container mx-auto border border-solid"}>
            <div className="py-12 bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="lg:text-center">
                        <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                            Welcome to ABCorp's Additive Printing TEST Portal
                        </p>
                        <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">

                        </p>
                    </div>
                    <div className="mt-10">
                        <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <dt className="text-lg leading-6 font-medium text-gray-900">
                                        State-of-the-art Manufacturing
                                    </dt>
                                    <dd className="mt-2 text-base text-gray-500">
                                        ABCorp provides advanced 3D printing capabilities through our Additive Manufacturing Center (AMC) in Boston, MA. The AMC is situated within our 125,000 square foot, highly secure, state-of-the-art facility and is powered by HP Jet Fusion 5210 industrial systems and Jet Fusion 580 full-color 3D printers
                                    </dd>
                                </div>
                            </div>

                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <dt className="text-lg leading-6 font-medium text-gray-900">
                                        Advanced Materials
                                    </dt>
                                    <dd className="mt-2 text-base text-gray-500">
                                        In addition to HP 3D Printing materials of HP 3D High Reusability PA 12, BASF Ultrasint® TPU01, HP 3D High Reusability PP enabled by BASF, and full-color HP 3D High Reusability CB PA 12, ABCorp offers finishing with AMT’s PostPro3D smoothing system.
                                    </dd>
                                </div>
                            </div>

                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <dt className="text-lg leading-6 font-medium text-gray-900">
                                        Read the latest version of our design guide
                                    </dt>
                                    <dd className="mt-2 text-base text-gray-500">
                                        HP Multi Jet Fusion (MJF) is an additive manufacturing process that builds parts layer-by-layer
                                        with the heating of powdered material and leveraging HP’s industry-leading print heads to apply
                                        fusing and detailing agents creating a 3D model.&nbsp;
                                        <a className={'text-indigo-400'} href={'/resources/design-guide.pdf'}>
                                            Download our latest design guide to learn more about this process.
                                        </a>

                                    </dd>
                                </div>
                            </div>

                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <dt className="text-lg leading-6 font-medium text-gray-900">
                                        Case Study: US Paralympics Curling
                                    </dt>
                                    <dd className="mt-2 text-base text-gray-500">
                                        In our latest whitepaper, we present how ABCorp and Brad Carlson teamed up to develop a next-generation curling head for
                                        the US Paralympics Curling Team.&nbsp;<a className={'text-indigo-600'} href={'/resources/us-curling-paralympics.pdf'}>Download it here.</a>
                                    </dd>
                                </div>
                            </div>
                        </dl>
                    </div>
                </div>
            </div>

        </div>
    )
}

const Dashboard = (props) => {
    return (<Dashview {...props}><Content/></Dashview>);
}

export default Dashboard;
