import React, {useState, useContext, useEffect} from "react";
import Dashview from "../Dashview";
import axios from 'axios';
import { v4 } from 'uuid';
import { Prompt, Redirect, useParams} from "react-router-dom";
import { useMachine } from '@xstate/react';
import { createMachine, assign } from 'xstate';
import ApxBusy from 'comps/ApxBusy';
import {AppContext} from "AppContext";
import Dropzone from "react-dropzone";
import { Dots } from "react-activity";
import "react-activity/dist/Dots.css";
import ApxUpload from "../../../comps/ApxUpload";
import ApxEditAddress from "comps/ApxEditAddress";

const Content = (props) => {

    const { id } = useParams();

    /* 
    --split 'order' react state into separate context fields in xstate machine - 6/18/24 ez
    const [order, set_order] = useState({
        customerPurchaseOrderId: "",
        originalInvoiceFilename: "",
        internalInvoiceFilename: "",
        parts: [],
    });
    */

    const [pricing, set_pricing] = useState([]);
    const [materials, set_materials] = useState([]);
    const units = [
        { text: "in", value: "UNITS_IN"},
        { text: "mm", value: "UNITS_MM"},
    ];


    /**
    const [orderButtonDisabled, setOrderButtonDisabled] = useState(true);
    */

    const [state, dispatch] = useMachine(() => {
        return createMachine({
            id: "component_state_machine",
            initial: "init",
            context : {
                id: id,
                placing_order: false,
                upload_dialog_visible: false,
                upload_dialog_percent: 0,
                upload_invoice_dialog_visible: false,
                redirect: null,
                orders_allowed: false,
                addresses: [],
                selected_address: 0,
                modal_addr: null,
                show_addr_modal: false,
                show_more_menu: true,
                parts: [],
                customerPurchaseOrderId: "",
                originalInvoiceFilename: "",
                internalInvoiceFilename: "",
                special_pricing: false,
                selected_unit: "UNITS_IN",
                service_charge: { finishing_surcharge: 0, floor_pricing: 0 }
            },
            on: {
                UPDATE_INVOICE: {
                    actions: [
                        assign({
                            originalInvoiceFilename: (context, event) => event.data.originalInvoiceFilename
                        }),
                        assign({
                            internalInvoiceFilename: (context, event) => event.data.internalInvoiceFilename
                        })
                    ],
                    internal: true,
                },
                ADD_ORDER_PART: {
                    actions: assign({
                        parts: (context, event) => context.parts.concat(event.data)
                    }),
                    internal: true,
                },
                ALLOW_ORDERS: {
                    actions: assign({
                        orders_allowed: (context, event) => event.data
                    }),
                    internal: true,
                },
                TOGGLE_ADDRESS_MODAL: {
                    actions: assign({
                        show_addr_modal: (_, event) => event.data
                    }),
                    internal: true,
                },
                SELECT_ADDRESS: {
                    actions: assign({
                        selected_address: (_, event) => event.data
                    }),
                    internal: true,
                },
                UPDATE_ADDRESSES: {
                    actions: assign({
                        addresses: (_, event) => event.data
                    }),
                    internal: true,
                },
                TOGGLE_MENU: {
                    actions: assign({
                        show_more_menu: (_, event) => event.data
                    }),
                    internal: true,
                },
                FLIP_SPECIAL_PRICING: {
                    actions: assign({
                        special_pricing: (_, event) => event.data
                    }),
                    internal: true,
                },
                SELECT_UNIT: {
                    actions: assign({
                        selected_unit: (_, event) => event.data
                    }),
                    internal: true,
                },
            },
            states : {
                init: {
                    invoke: {
                        src: on_init_state_machine,
                        onDone: {
                            target: 'ready'
                        },
                        onError: {

                        }
                    }
                },
                ready: {
                    on: {
                        FILES_DROPPED: {
                            target: 'process_dropped_files',
                            actions: [
                                assign({
                                    upload_dialog_visible: (context, event) => true,
                                    upload_dialog_percent: (context, event) => 0
                                })
                            ]
                        },
                        REQUEST_UPLOAD_CUSTOM_INVOICE: {
                          target: 'select_custom_invoice_file',
                          actions: [
                              assign({
                                  upload_invoice_dialog_visible: (context, event) => true
                              })
                          ]
                        },
                        REQUEST_PLACE_ORDER: {
                            target: 'process_place_order',
                            actions: [
                                assign({
                                    placing_order: (_, event) => true
                                })
                            ]
                        },
                        CHANGE_PURCHASE_ORDER_NUMBER: {
                            actions: assign({
                                customerPurchaseOrderId: (context, event) => event.data
                            })
                        },
                        CHANGE_ORDER_PART: {
                            actions: assign({
                                parts: (context, event) =>  {
                                    let newParts = context.parts;
                                    for(let i = 0; i !== newParts.length; i++) {
                                        if(newParts[i].id === event.data.id) {
                                            newParts[i] = event.data.data;
                                        }
                                    }
                                    return newParts;
                                },
                                service_charge: (context, _) => {
                                    let finishing_volume = context.parts.reduce(
                                        (sum, part) => part.material === "Finishing Only" ? +part.quantity * part.volume + sum : sum, 0
                                    );
                                    if (finishing_volume > 0 && finishing_volume < 101) {
                                        return {...context.service_charge, finishing_surcharge: 150};
                                    } 
                                    return {...context.service_charge, finishing_surcharge: 0};
                                }
                            })
                        },
                        DELETE_ORDER_PART: {
                            actions: assign({
                                parts: (context, event) => context.parts.toSpliced(event.data, 1)
                            })
                        }
                    }
                },
                select_custom_invoice_file: {
                    on: {
                        CANCEL_UPLOAD_CUSTOM_INVOICE: {
                            target: "ready",
                            actions: [
                                assign({
                                    upload_invoice_dialog_visible: (context, event) => false
                                })
                            ]
                        },
                        ACCEPT_UPLOAD_CUSTOM_INVOICE: {
                            target: "process_upload_invoice",
                            actions: [
                                assign({
                                    upload_invoice_dialog_visible: (context, event) => false,
                                    upload_dialog_visible: (context, event) => true,
                                })
                            ]
                        }
                    }
                },
                process_upload_invoice: {
                  invoke: {
                      src: on_process_upload_invoice,
                      onDone: {
                          target: 'ready',
                          actions: [
                              assign({
                                  upload_dialog_visible: (context, event) => false,
                                  upload_dialog_percent: (context, event) => 0,
                              })
                          ]
                      },
                      onError: {
                          target: 'ready',
                          actions: [
                              assign({
                                  upload_dialog_visible: (context, event) => false,
                                  upload_dialog_percent: (context, event) => 0,
                              })
                          ]
                      }
                  }
                },
                process_dropped_files: {
                    invoke: {
                        src: on_process_dropped_files,
                        onDone: {
                            target: 'ready',
                            actions: [
                                assign( {
                                    upload_dialog_visible: (context, event) => false,
                                    upload_dialog_percent: (context, event) => 0,
                                })
                            ]
                        },
                        onError: {
                            actions: [
                                assign( {
                                    upload_dialog_visible: (context, event) => false,
                                    upload_dialog_percent: (context, event) => 0
                                })
                            ]
                        }
                    },
                    on: {
                        PROGRESS_UPDATE: {
                            actions: [
                                assign({ upload_dialog_percent: (context, event) => event.data})
                            ]
                        }
                    }
                },
                process_place_order: {
                    invoke: {
                        src: on_process_place_order,
                        onDone: {
                            target: 'process_place_order_success',
                            actions: [
                                assign({ 
                                    placing_order: (_, event) => false,
                                }),
                            ]
                        },
                        onError: {
                            target: 'process_place_order_failure',
                            actions: [
                                assign({ 
                                    placing_order: (_, event) => false,
                                }),
                            ]
                        }
                    },
                },
                process_place_order_success: {
                    entry: [
                        assign({ redirect: (context, event) => "/quotes/submitted"})
                    ]
                },
                process_place_order_failure: {
                    on: {
                        RETURN_TO_READY: {
                            target: 'ready',
                        }
                    },
                    invoke: {
                        src: on_place_order_failure,
                        onDone: {
                            //target: 'ready',
                            actions: [assign({
                                upload_dialog_visible: (context, event) => true})]
                        }
                    }
                }
            }
        })
    })

    const { identity, config } = useContext(AppContext);
    
    async function on_init_state_machine(context, event) {
        
        // Retrieve the pricing and materials tables from the server side of the system:
        // Retrieve material specifications from the database:
        let materials_response = await axios.get(config.api.base + '/materials');
        set_materials(materials_response.data);

        // Retrieve pricing details from the database:
        let pricing_response = await axios.get(config.api.base + '/pricing');

        // If the customer pricing table doesn't contain all materials,
        // retrieve pricing details from default table
        let default_response = await axios.get(config.api.base + '/pricing/tables/27e0793a-2eb6-4418-9f5b-2dc900734a88');
        pricing_response = pricing_response.data.pricing.filter( cust_price => cust_price.offsets.length > 0);
        // filters for materials the customer specific price table does not have
        let default_prices = default_response.data.pricing.filter( d_price => { 
            return !pricing_response.some( cust_price => cust_price.material === d_price.material ) 
        })
        
        if (identity.userAcctId === '5d28fdb0-cc46-4349-aafd-4403bc8809d6') {
            dispatch({ type: "FLIP_SPECIAL_PRICING", data: true })
        }
        
        set_pricing(pricing_response.concat(default_prices));

        const addresses_api_response = await axios.get(config.api.base + '/addresses/account/' + identity.userAcctId);
        console.log(addresses_api_response);
        context.addresses = addresses_api_response.data;
        const default_address_index = context.addresses.findIndex((address) => address.default);
        if (default_address_index > 0) context.selected_address = default_address_index;

        // Determine whether or not we are looking at an
        // existing record, or are creating a new one:
        if(id !== "new") {
            // We need to load a record from the database, since we are reviewing
            // a record instead of creating a new one:
            let order_response = await axios.get(config.api.base + "/orders/" + id);

            context.customerPurchaseOrderId = order_response.data.metadata.customerPurchaseOrderId;
            context.originalInvoiceFilename = order_response.data.metadata.originalInvoiceFilename;
            context.internalInvoiceFilename = order_response.data.metadata.internalInvoiceFilename;
            context.parts = order_response.data.metadata.parts;

            // --split 'order' react state into separate context fields in xstate machine - 6/18/24 ez
            // set_order(order_response.data.metadata);
            // console.log(order_response.data.metadata);
        }
    }

    async function on_process_upload_invoice(context, event) {
        console.log("::::: UPLOAD CUSTOM INVOICE :::::");
        console.log(event);

        const formData = new FormData();
        const generatedName = v4() + '.' +  event.data[0].name.split('.').pop();
        formData.append("file-1" , event.data[0], generatedName);

        const response = await axios.post(config.api.base + '/temp', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: on_upload_progress
        })

        // We now have the original filename and the generated name
        // that is used to identify the file on the server. We need
        // to add both of those fields to the order record:
        dispatch({ 
            type: "UPDATE_INVOICE", 
            data: {
                originalInvoiceFilename: event.data[0].name, 
                internalInvoiceFilename: generatedName
            } 
        })

        // --split 'order' react state into separate context fields in xstate machine - 6/18/24 ez
        // order.originalInvoiceFilename = event.data[0].name;
        // order.internalInvoiceFilename = generatedName;
    }

    async function on_process_place_order(context, event) {

        // temporary implementation for Finishing Only surcharge - added 10/21/24 ez
        // to be changed when minimum pricing is added and metadata structure is changed
        let charged_parts = structuredClone(context.parts);
        if (Object.values(context.service_charge).some((charge) => charge > 0)) { 
            let finishing_index = charged_parts.findIndex((part) => part.material === "Finishing Only");
            charged_parts[finishing_index].price_total += 150;
        }

        // temporary implementation for addresses - added 01/06/25 ez
        // to be changed when 3DtoVisual app is ready to accept addresses
        const ship_address = context.addresses[context.selected_address];
        const city_state_zip = ship_address.city + ", " + ship_address.state + " " + ship_address.zip;
        const address_string = [
            ship_address.name, 
            ship_address.org, 
            ship_address.address_1, 
            ship_address.address_2, 
            city_state_zip
        ].filter(Boolean).join("\n");
        charged_parts[0].comments.concat("\nShipping Address for Entire Order: \n", address_string);

        try {
            const record = {
                address: context.addresses[context.selected_address], 
                metadata : {
                    parts: [...charged_parts], // [...context.parts]
                    customerPurchaseOrderId: context.customerPurchaseOrderId,
                    originalInvoiceFilename: context.originalInvoiceFilename,
                    internalInvoiceFilename: context.internalInvoiceFilename
                }
            }
            console.log("::::: PLACED ORDER :::::")
	        console.log(record);
            await axios.post(config.api.base + "/orders", record).catch((error) => {
                if (error.response) console.log("ERROR: " + error.response.data);
                throw error;
            });
        } catch(x) {
            throw new Error("An error occurred while attempting to place this order.", { cause: x });
        }
    }

    function on_place_order_failure(context, event) {
        //handle conveying error to user
        dispatch({ type: "RETURN_TO_READY" });
    }

    async function on_process_dropped_files(context, event) {
        //const parts = []
        const formData = new FormData();
        for (let i = 0; i !== event.data.length; i++) {
            //const part_identifier = v4();
            //const generatedName = part_identifier + '.' + event.data[i].name.split('.').pop();
            formData.append("file-" + i, event.data[i]);
        }

        const response = await axios.post(config.api.base + '/parts', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: on_upload_progress
        })

        // --split 'order' react state into separate context fields in xstate machine - 6/18/24 ez
        // order.parts = order.parts.concat(response.data);

        //context.parts = context.parts.concat(response.data);
        dispatch({ type: "ADD_ORDER_PART", data: response.data })
    }

    function on_upload_progress(event) {
        const percentage = 100 * (event.loaded / event.total);
        dispatch({type: "PROGRESS_UPDATE", data: percentage})
    }

    function on_files_dropped(files) {
        dispatch({ type: "FILES_DROPPED", data: files})
    }

    function on_part_changed(args) {
        // --split 'order' react state into separate context fields in xstate machine - 6/18/24 ez
        // Find the part in the collection:
        // for(let i=0;i!=order.parts.length;i++) {
        //     if(order.parts[i].id === args.id) {
        //         order.parts[i] = args.data;
        //     }
        // }
        // set_order({...order})

        dispatch({ type: "CHANGE_ORDER_PART", data: args })
    }

    function on_assign_invoice_number(args) {
        // --split 'order' react state into separate context fields in xstate machine - 6/18/24 ez
        // order.customerPurchaseOrderId = args.target.value;
        // set_order({...order});

        dispatch({ type: "CHANGE_PURCHASE_ORDER_NUMBER", data: args.target.value });
    }

    function on_upload_custom_invoice(args) {
        dispatch({ type: "REQUEST_UPLOAD_CUSTOM_INVOICE", data: {} });
    }

    function on_clicked_upload_invoice_okay(args) {
        dispatch({ type: "ACCEPT_UPLOAD_CUSTOM_INVOICE", data: args })
    }

    function on_clicked_upload_invoice_cancel(args) {
        dispatch({ type: "CANCEL_UPLOAD_CUSTOM_INVOICE", data: {} })
    }

    function on_clicked_place_order(args) {
        dispatch({ type: "REQUEST_PLACE_ORDER", data: {}})
    }

    function on_accept_terms(args) {
        if (args.target.checked) {
            dispatch({ type: "ALLOW_ORDERS", data: true })
        } else {
            dispatch({ type: "ALLOW_ORDERS", data: false })
        }

    } 

    function on_toggle_menu() {
        dispatch({ type: "TOGGLE_MENU", data: !state.context.show_more_menu })
    }

    function on_flip_special_pricing(args) {
        if (args.target.checked) {
            dispatch({ type: "FLIP_SPECIAL_PRICING", data: true })
        } else {
            dispatch({ type: "FLIP_SPECIAL_PRICING", data: false })
        }
    }

    function on_change_selected_unit(args) {
        dispatch({ type: "SELECT_UNIT", data: args.target.value });
    }

    // If a redirect target is specified, redirect to it:
    if(state.context.redirect != null) {
        return <Redirect to={state.context.redirect}/>
    }

    let order_status = "incomplete";
    if(id !== "new") {
        order_status = "complete";
    }

    /** To add comments when T&C link is ready
    if(state.context.parts.length > 0) {
        let ordersPending = false;
        for(let i=0;i!=state.context.parts.length;i++) {
            if(!state.context.parts[i].snapshot_filename) {
                ordersPending = true;
            }
        }
        if(ordersPending === false && orderButtonDisabled !== false) {
            setOrderButtonDisabled(false);
        } else if(ordersPending === true && orderButtonDisabled === false) {
            setOrderButtonDisabled(true);
        }
    }
    */

    function onDeletePart(index) {
        // --split 'order' react state into separate context fields in xstate machine - 6/18/24 ez
        // order.parts.splice(index, 1);
        // set_order({...order});

        dispatch({ type: "DELETE_ORDER_PART", data: index });
    }
    
    function onAddNewAddr(_) {
        dispatch({ type: "TOGGLE_ADDRESS_MODAL", data: true });
    }

    function onCancelNewAddr() {
        dispatch({ type: "TOGGLE_ADDRESS_MODAL", data: false });
    }
    
    function onSaveNewAddr(new_addr) {
        const action = async () => {
            try {
                new_addr.acctId = identity.userAcctId;
                console.log(new_addr);

                if (new_addr.default) { //what if there is no default in old addresses?
                    const old_default_addr_index = state.context.addresses.findIndex((addr) => addr.default);
                    const old_default_addr = { ...state.context.addresses[old_default_addr_index], default: false};
                    await axios.put(config.api.base + '/addresses/' + old_default_addr.id, old_default_addr);
                }

                const response = await axios.post(config.api.base + '/addresses', new_addr);
                console.log(response);

                const action = async () => {
                    const address_api_response = await axios.get(config.api.base + '/addresses/account/' + identity.userAcctId);
                    dispatch({ type: "UPDATE_ADDRESSES", data: address_api_response.data })
                    dispatch({ type: "SELECT_ADDRESS", data: address_api_response.data.length-1 })
                    console.log(address_api_response.data);
                }
                action();

            } catch (e) {}
        }
        action();
        dispatch({ type: "TOGGLE_ADDRESS_MODAL", data: false });
    }

    function onSaveEditAddr(_) {    }

    function onChangeAddrTable(args) {
        dispatch({ type: "SELECT_ADDRESS", data: args.target.value });
    }

    let numParts = state.context.parts.reduce((sum, current) => current.quantity ? +current.quantity + sum : sum, 0);
    let orderTotal = state.context.parts.reduce((sum, current) => current.price_total ? current.price_total + sum : sum, 0);
    orderTotal += Object.values(state.context.service_charge).reduce((sum, current) => current + sum, 0);
    
    function on_click_testing(_) {
        console.log(state.context);
    }
    function on_click_testing2(_) {
        let charged_parts = structuredClone(state.context.parts);
        if (Object.values(state.context.service_charge).some((charge) => charge > 0)) { 
            let finishing_index = state.context.parts.findIndex((part) => part.material === "Finishing Only");
            charged_parts[finishing_index].price_total += 150;
        }
        console.log(charged_parts);
    }

    if (state.context.addresses === null) return null;

    return(
        <div className={"container mx-auto"}>
            <div>
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Additive Printing - New Order{identity.userAdmin && ` - ${identity.userComp}`} {state.value}
                    </h2>
                    <p className="mt-2 text-center text-xl text-gray-600">
                        You can upload 3MF/STL files here to create an order.
                    </p>
                </div>
            </div>
            <div className={"w-full mt-4 mb-4 space-y-2"}>
                <div className={"flex w-full space-x-4"}>
                    {state.context.addresses.length ?
                        <div className={"items-left"}>
                            <label htmlFor="shipAddress" className={"block text-sm font-medium text-gray-700"}>
                                Shipping Address for All Lines:
                            </label>
                            <select
                                id="shipAddress"
                                value={state.context.selected_address}
                                onChange={onChangeAddrTable}
                                size='1'
                                style={{maxWidth: "217px"}} 
                                className="mt-1 block w-full py-2 px-3 overflow-ellipsis border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                {state.context.addresses.map((item, index) => {
                                    const state_zip = item.state + " " + item.zip;
                                    let address_string = [item.name, item.org, item.address_1, item.address_2, item.city, state_zip].filter(Boolean).join(", ");
                                    return <option key={index} value={index}>{address_string}</option>
                                })}
                            </select>
                        </div>
                        :
                        <div className={"items-left"}>
                            No stored addresses.
                        </div>
                    }
                    <div className={"content-end items-left"}>
                        <button className={"standardButton h-10"} onClick={onAddNewAddr} style={{ marginRight: '5px'}}>
                            Add Address
                        </button>
                    </div>
                    <div className={"content-end items-left"}>
                        <button className={"standardButton h-10"} onClick={on_toggle_menu} style={{ marginRight: '5px'}}>
                            {
                                state.context.show_more_menu ?
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12" />
                                    </svg>
                                </div> 
                                :
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0-3.75-3.75M17.25 21 21 17.25" />
                                    </svg>
                                </div>
                            }
                        </button>
                    </div>
                    {
                        identity.userAdmin && 
                        <div className={"flex justify-end flex-grow items-end"}>
                            <div className={"mb-1 mr-2 text-gray-700"}>
                                Pricing by: 
                            </div>
                            <div className={ "mr-2 mb-1 font-medium " + (state.context.special_pricing ? "text-gray-300" : "text-green-350") }>
                                Volume
                            </div>
                            <label className={"switch"}>
                                <input type="checkbox" onChange={on_flip_special_pricing} checked={state.context.special_pricing}/>
                                <span className={"slider round"}></span>
                            </label>
                            <div className={ "ml-2 mb-1 font-medium " + (state.context.special_pricing ? "text-indigo-500" : "text-gray-300") }>
                                Part
                            </div>
                        </div>
                    }
                </div>
                <div className={ state.context.show_more_menu? "flex w-full space-x-4" : "hidden"}>
                    <div className={"items-left"}>
                        <label htmlFor="units" className="block text-sm font-medium text-gray-700">
                            Display Units
                        </label>
                        <select id="units" name="units" value={state.context.selected_unit} onChange={on_change_selected_unit} disabled={ state.context.parts.some((part) => !part.snapshot_filename) } className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            { units.map((item, index) => {
                                return (
                                    <option value={item.value} key={index}>{item.text}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className={"items-left"}>
                        <label htmlFor="invoiceNumber" className={"block text-sm font-medium text-gray-700"}>
                            Customer Purchase Order #:
                        </label>
                        <input id={"invoiceNumber"} type={"text"} value={state.context.customerPurchaseOrderId} onChange={on_assign_invoice_number} size='25' className={"mt-1 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}/>
                    </div>
                    <div className={"items-left"}>
                        <label htmlFor="invoiceNumber" className={"block text-sm font-medium text-gray-700"}>
                            Customer Purchase Order:
                        </label>
                        <input id={"invoiceFile"} type={"text"} value={state.context.originalInvoiceFilename} size='25' readOnly={true} className={"mt-1 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none sm:text-sm"}/>
                    </div>
                    <div className={"flex ml-4 content-end items-end"}>
                        <button className={"standardButton h-10"}  onClick={on_upload_custom_invoice} style={{ marginRight: '5px'}}>
                            <div className={"flex"}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                            </svg>
                                <div>Attach Purchase Order</div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <div className={state.context.parts.length > 0 ? "w-full border-t border-b border-gray-400 divide-y divide-gray-400 rounded-md mt-6" : "w-full"}>
                {
                    state.context.parts.map((item, index) => {
                        return(
                            <Part 
                                key={item.id} 
                                part={item} 
                                onDelete={()=>{onDeletePart(index)}} 
                                order_status={order_status} 
                                special_pricing={state.context.special_pricing}
                                display_unit={state.context.selected_unit}
                                on_changed={(e)=>on_part_changed(e)} 
                                pricing={pricing} 
                                materials={materials}>
                            </Part>
                        );
                    })
                }
            </div>
            <div className={"w-full border border-dashed border-gray-600 rounded-sm bg-indigo-50 h-16 mt-4 mb-4"}>
                <Dropzone  onDrop={on_files_dropped} accept=".stl,.3mf">
                    {({getRootProps, getInputProps}) => (
                        <section style={{ height: "100%"}}>
                            <div style={{ height: "100%"}} className={"flex items-center justify-center"} {...getRootProps()}>
                                <input {...getInputProps()} />
                                <p>Drag and drop 3MF/STL files here, or click to select files</p>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>
            <div className={"flex py-2 justify-between"}>
                <div className={"text-2xl text-gray-900"}>
                    Order total: 
                    <span className={"font-bold"}>
                        &nbsp;${ orderTotal.toFixed(2) }&nbsp;
                    </span>
                    for 
                    { numParts === 1 ? " 1 item" : " " + numParts + " items" } 
                </div>
                <div>
                    <button onClick={on_click_testing}>Testing</button>
                    <button onClick={on_click_testing2}>FnshgSrchg Test</button>
                </div>
                <div className={"flex flex-row"}>
                    { //!identity.userAdmin && 
                        <div className={"flex items-center pr-4"}>
                            <label>
                                <input type="checkbox" onChange={on_accept_terms} style={{ verticalAlign: '-1px' }}/>
                                &nbsp;I have read and agree to the <a className={"text-indigo-500"} href="https://www.google.com" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>.
                            </label> 
                        </div>
                    } 
                    <button disabled={ true } className={"uploadButton hidden"} onClick={on_clicked_place_order} style={{ marginRight: '5px'}}>
                        Request Quote{/*button was always hidden so set disabled to true - 6/18/24 ez*/}
                    </button>
                    <button disabled={ !state.context.orders_allowed || (state.context.parts.length === 0) || state.context.parts.some((part) => !part.snapshot_filename) } 
                    className={"estimateButton"} onClick={on_clicked_place_order} style={{ marginRight: '5px'}}>
                        Place Order
                    </button>
                </div>
            </div>
            { state.context.parts.length > 3 &&
                <div className={"flex pt-4 pb-1 justify-center"}>
                    <button onClick={ () =>  window.scroll({top: 0, behavior: 'smooth'}) } className={"cursor-pointer text-sm text-indigo-500"}>
                        Jump to Top
                    </button>
                </div>
            }
            <ApxEditAddress show={state.context.show_addr_modal} data={state.context.modal_addr} onNew={onSaveNewAddr} onEdit={onSaveEditAddr} onCancel={onCancelNewAddr}></ApxEditAddress>
            <ApxBusy message={"Uploading Files..."} show={state.context.upload_dialog_visible} show_progress={true} percentage={state.context.upload_dialog_percent}/>
            <ApxBusy message={"Placing Order..."} show={state.context.placing_order} show_progress={false} percentage={0}/>
            <ApxUpload show={state.context.upload_invoice_dialog_visible} onClickedOkay={on_clicked_upload_invoice_okay} onClickedCancel={on_clicked_upload_invoice_cancel}/>
            <Prompt when={true} message="Any data you have entered on this page will be lost, are you sure you want to leave this page?" />
        </div>
    );
}

const Part = (props) => {

    const { identity, config } = useContext(AppContext);

    const [units] = useState([
        { text: "mm", value: "UNITS_MM"},
        { text: "in", value: "UNITS_IN"}
    ])


    // material g-uids maintained here for backwards compatibility
    const [finishes_by_material] = useState([
        {   // HP Polypro
            material: ["0d96b14d-99d1-42cb-a767-3f10a8d61969", "HP Polypro"], 
            finishes: [
                { text: "MJF Finish",       value: "MJF_FINISH"}
            ]
        },
        {   // HP-PA12, BASF-TPU Ultrisint, PA12CB-Color, HP-PA11
            material: [
                "e9a780a7-e878-46aa-8ff8-65c9991233a6", "9eec4c4e-ceb6-4ba9-83c0-405ad3207874", "HP-PA12", "BASF-TPU Ultrisint",
                "630f0469-63d1-4d40-82bd-69654ae4c494", "832a93bc-d16a-4a93-b6d9-419e7a89b021", "PA12CB-Color", "HP-PA11",
                "HP-PA12 Polar White"
            ], 
            finishes: [
                { text: "MJF Finish",       value: "MJF_FINISH"},
                { text: "Vapor Smooth",     value: "VAPOR_SMOOTHING"},
                { text: "Black Dye",        value: "BLACK_DYE"},
                { text: "Vapor Smooth / Black Dye",  value: "AMT_BLACK_DYE"}
            ]
        },
        {   // Finishing Only
            material: ["Finishing Only"],
            finishes: [
                { text: "Vapor Smooth",     value: "VAPOR_SMOOTHING"},
                { text: "Black Dye",        value: "BLACK_DYE"},
                { text: "Vapor Smooth / Black Dye",  value: "AMT_BLACK_DYE"}
            ]
        },
        {   // 174 PH SS
            material: ["174PH SS"],
            finishes: [
                { text: "Sinter",           value: "SINTER"}
            ]
        }
    ])

    //const [finish_disabled, set_finish_disabled] = useState(false);

    useEffect(()=>{
        // If the snapshot_filename property isn't set, then
        // the part has not yet been fully processed on the
        // server side of the system:
        if(!props.part.snapshot_filename) {
            // So we start polling the server until the part
            // has been fully rendered:
            poll_for_rendered_part(props.part.internal_filename);
        }
    }, [])

    useEffect(() => {
        if (props.part.snapshot_filename && !props.special_pricing) {
            console.log("--Pricing toggled to use Volume--");
            props.part.price_per_component = compute_component_price();
            props.part.price_total = compute_total_price();
            props.on_changed({ id: props.part.id, data: {...props.part}})
        }
    }, [props.special_pricing])

    useEffect(() => {
        if (props.part.snapshot_filename) {
            change_units(props.display_unit)
        }
    }, [props.display_unit])

    function poll_for_rendered_part(internal_filename) {
        const action = async () => {
            try {
                const result = await axios.get(config.api.base + '/parts/' + internal_filename)
                if(result.status === 204) {
                    setTimeout(() => poll_for_rendered_part(internal_filename), 1000);
                } else if (result.status === 200) {
                    // abcorp-3ds-gen will return volume and bounds in mm 
                    props.part.units = props.display_unit;
                    props.part.quantity = 1;
                    props.part.material = "HP Polypro";
                    props.part.finish = "MJF_FINISH";
                    props.part.volume = props.display_unit === "UNITS_IN" ? 
                                        Number(result.data.model_volume) / 16387.064 : 
                                        Number(result.data.model_volume);
                    props.part.price_per_component = compute_component_price();
                    props.part.price_total = compute_total_price();
                    props.part.comments = "";
                    props.part.bounds = props.display_unit === "UNITS_IN" ? 
                                        calc_bounds(result.data.model_bounds, false) : 
                                        result.data.model_bounds;
                    props.part.snapshot_filename = result.data.model_snapshot;
                    props.on_changed({ id: props.part.id, data: {...props.part}})
                    console.log("RESULT IS BACK, VOLUME IS : " + props.part.volume)
                }
            } catch(x) {
                console.log(x);
            }
        }
        action();
    }

    // Determines the price of the part, based on the
    // selected options (material, finish, and so on):
    function compute_component_price() {
        if (props.special_pricing && props.part.price_per_component !== undefined) {
            return props.part.price_per_component;
        }

        // Find the selected material's id in the price
        // table and use that table for recalculating:
        let relevantOffsets;
        for(let x=0;x!==props.pricing.length;x++) {
            if (props.pricing[x].material === props.part.material) {
                relevantOffsets = props.pricing[x].offsets;
                break;
            }
        }

        let normalized_volume = props.part.volume;
        // if currently displaying mm, we convert to calculate price by inches -- 9/16/2024 ez
        if (props.part.units === "UNITS_MM") normalized_volume = normalized_volume / 16387.064;

        //console.log("NORMALIZED VOLUME: " + normalized_volume);
        //if(props.part.units === "UNITS_IN") {
        //    console.log("ADJUSTING TO INCHES")
        //    normalized_volume = props.part.volume / 16387.064;
        //    console.log("NOW IT'S: " + normalized_volume);
        //}

        let total_volume = props.part.quantity * normalized_volume;

        // REC: Removing the roundup of volume, per Neil's request:
        //if(normalized_volume < 1 ) {
        //    total_volume = props.part.quantity;
        //}

        console.log("::::: TOTAL VOLUME CALCULATED :::::");
        console.log("Total volume is = " + total_volume);

        // Special case: Finishing Only material is priced by finish intead of by volume
        // In case both finish and volume are required, research godel numbering -- 9/16/2024 ez
        if (props.part.material === "Finishing Only") {
            console.log("::::: DETERMINING GRAY PARTS COST :::::");
            
            let finishNum = 1;
            switch (props.part.finish) {
                case "VAPOR_SMOOTHING":
                    finishNum = 5;
                    break;
                case "BLACK_DYE":
                    finishNum = 3;
                    break;
                case "AMT_BLACK_DYE":
                    finishNum = 7;
                    break;
                default:
            }

            let grayPartsCost = 0;
            for(let index = 0; index !== relevantOffsets.length; index++) {
                if(finishNum === relevantOffsets[index].max) {
                    grayPartsCost = total_volume * relevantOffsets[index].gray;
                    console.log("Gray Parts Cost = " + grayPartsCost)
                    break;
                }
            }
            
            let perPartPrice = grayPartsCost / props.part.quantity;
            console.log("::::: PER PART PRICE :::::")
            console.log("Per part price is = " + perPartPrice)

            perPartPrice = +(Math.round(perPartPrice + "e+2")  + "e-2");
            console.log("Rounded to: $" + perPartPrice);

            return perPartPrice;
        }

        let grayPartsCost = 0;
        //let priceByVolume = 0;

        console.log("::::: DETERMINING GRAY PARTS COST :::::");
        for(let index=0;index!==relevantOffsets.length;index++) {
            if(total_volume >= relevantOffsets[index].max) {
                console.log("Total volume is greater than or equal to index " + index + " in the pricing table.")
                grayPartsCost = total_volume * relevantOffsets[index].gray;
                console.log("Gray Parts Cost = " + grayPartsCost)
                break;
            }
        }

        let perPartPrice = grayPartsCost / props.part.quantity;
        if((props.part.material === "HP-PA12") || (props.part.material === "BASF-TPU Ultrisint") ||
           (props.part.material === "HP-PA11") || (props.part.material === "PA12CB-Color") || 
           (props.part.material === "HP-PA12 Polar White")) {
            switch(props.part.finish) {
                case "VAPOR_SMOOTHING":
                    perPartPrice = perPartPrice + (perPartPrice * .20);
                    break;
                case "BLACK_DYE":
                    perPartPrice = perPartPrice + (perPartPrice * .10);
                    break;
                case "AMT_BLACK_DYE" :
                    perPartPrice = perPartPrice + (perPartPrice * .20);
                    break;
                default :
            }
        }

        console.log("::::: PER PART PRICE :::::")
        console.log("Per part price is = " + perPartPrice)

        perPartPrice = +(Math.round(perPartPrice + "e+2")  + "e-2");
        console.log("Rounded to: $" + perPartPrice);

        return perPartPrice;

    }

    function compute_total_price() {
        let totalPrice = props.part.price_per_component * props.part.quantity;
        return totalPrice;
    }

    function change_units(unit) {
        switch(props.part.units) {
            case "UNITS_IN" :
                if(unit === "UNITS_MM") {
                    console.log("SWITCHING FROM IN TO MM");
                    props.part.volume = props.part.volume * 16387.064;
                    props.part.bounds = calc_bounds(props.part.bounds, true);
                }
                break;
            case "UNITS_MM" :
                if(unit === "UNITS_IN") {
                    console.log("SWITCHING FROM MM TO IN");
                    props.part.volume = props.part.volume / 16387.064;
                    props.part.bounds = calc_bounds(props.part.bounds, false);
                }
                break;
            default :
        }

        props.part.units = unit;
        props.on_changed({ id: props.part.id, data: {...props.part}})
    }

    function calc_bounds(bounds, is_multiply) {
        if (is_multiply) {
            return {
                "x": bounds.x * 25.4,
                "y": bounds.y * 25.4,
                "z": bounds.z * 25.4
            }
        } else {
            return {
                "x": bounds.x / 25.4,
                "y": bounds.y / 25.4,
                "z": bounds.z / 25.4
            }
        }
    }

    function on_change_material(args) {
        console.log("Changing material to : " + args.target.value);
        props.part.material = args.target.value;
        props.part.finish = finishes_by_material.filter( 
                                finish_object => finish_object.material.includes(props.part.material)
                            )[0].finishes[0].value;
        props.part.price_per_component = compute_component_price();
        props.part.price_total = compute_total_price();
        props.on_changed({ id: props.part.id, data: { ...props.part}})
    }

    function on_change_finish(args) {
        props.part.finish = args.target.value;
        props.part.price_per_component = compute_component_price();
        props.part.price_total = compute_total_price();
        props.on_changed({id: props.part.id, data: {...props.part}})
    }

    function on_change_quantity(args) {
        if (args.target.validity.stepMismatch) args.target.value = Math.floor(args.target.value);
        props.part.quantity = args.target.validity.rangeUnderflow ? 1 : args.target.value;
        props.part.price_per_component = compute_component_price();
        props.part.price_total = compute_total_price();
        props.on_changed({ id: props.part.id, data: { ...props.part}})
    }

    function on_change_part_price(args) {
        if (props.special_pricing) {
            let inputPrice = (args.target.value <= 0 || args.target.value === null) ? 0.00 : +args.target.value;
            if (args.target.value.match(/^\d+\.\d{3}$/)) inputPrice = +args.target.value.slice(0, -1);
            props.part.price_per_component = inputPrice;
            props.part.price_total = compute_total_price();
            props.on_changed({ id: props.part.id, data: { ...props.part}})
        }
    }

    function on_change_comments(args) {
        props.part.comments = args.target.value;
        props.on_changed({ id: props.part.id, data: { ...props.part}});
    }

    function highlight_all(args) {
        args.target.select();
    }

    function on_click_testing(args) {
        console.log(props.part);
    }

    let display_volume = props.part.volume;
    //if(props.part.units === "UNITS_IN") {
    //    display_volume = props.part.volume / 16387.064;
    //}

    let display_bounds = "0,0,0";
    if(props.part.bounds) {
        display_bounds = Number(props.part.bounds.x).toFixed(2);
        display_bounds += ' / ';
        display_bounds += Number(props.part.bounds.y).toFixed(2);
        display_bounds += ' / ';
        display_bounds += Number(props.part.bounds.z).toFixed(2);
    }

    /** Use finishes_by_material mapping to determine finishes instead -- 9/10/2024 ez
    if(finish_disabled === false && props.part.material === "0d96b14d-99d1-42cb-a767-3f10a8d61969") {
        set_finish_disabled(true);
    }
    
    if(finish_disabled === true && props.part.material != "0d96b14d-99d1-42cb-a767-3f10a8d61969") {
        set_finish_disabled(false);
    }
    */
    
    if(!props.part.snapshot_filename) {
        return (
            <div className={"flex flex-row border-2 border-black mt-2"}>
                <div style={{ width: "196px", height: "196px"}}>
                    <img src={"/loading.gif"} style={{ height: "100%"}} alt="A loading gif - Many 2D planes moving and intersecting in 3D" />
                </div>
                <div className={"flex-col text-center w-full pt-8"}>
                    <div className={"text-3xl"}>{props.part.original_filename}</div>
                    <div>The server is currently processing this model.</div>
                    <div>This may take seconds or minutes, depending on the size of the model.</div>
                    <Dots size={32}/>
                </div>
            </div>
        )
    } else {
        // The fully-qualified path to the location of the snapshot image for this part:
        let snapshot_path = config.api.base + '/temp/' + props.part.snapshot_filename;
        if(props.order_status === "complete") {
            snapshot_path = config.api.base + '/orders/' + identity.userAcctId + '/' + props.part.snapshot_filename;
        }
        let finishes = finishes_by_material.filter( finish_object => finish_object.material.includes(props.part.material) )[0].finishes;
        
        return (
            <div className={"flex flex-rowpt-2 rounded-sm"}>
                <div className={"flex flex-col space-y-1"} style={{ width: "202px"}}>
                    <img src={snapshot_path} style={{ width: "170px", height: "170px"}} className={"ml-4 mt-4"} alt={"Screenshot of part " + props.part.original_filename}/>
                    <div className={"pl-4 pb-2"}>
                        <p className={"text-sm whitespace-nowrap"}>
                            <span className={"font-medium"}>Model Filename:</span><br/>
                            {props.part.original_filename}
                        </p>
                    </div>
                </div>
                <div className={"flex flex-col flex-grow space-y-2"}>
                <div className={"flex flex-row flex-nowrap justify-center items-center space-x-4 pt-2 pl-4"}>
                    <div>
                        <label htmlFor="material" className="block text-sm font-medium text-gray-700">
                            Material
                        </label>
                        <select id="material" name="material" value={props.part.material} onChange={on_change_material} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            { props.materials.map((item, index) => {
                                return (
                                    <option value={item.name} key={index}>{item.name}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div>
                        <label htmlFor="finish" className="block text-sm font-medium text-gray-700">
                            Finish
                        </label>
                        <select id="finish"  name="finish" value={props.part.finish} onChange={on_change_finish} style={{minWidth: "208px"}} className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                            { finishes.map((item, index) => {
                                return (
                                    <option value={item.value} key={index}>{item.text}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div>
                        <label htmlFor={'volume'} className={"block text-sm font-medium text-gray-700"}>Volume</label>
                        <input id={"volume"} type={"text"} size={10} readOnly={true} value={display_volume.toFixed(2)}  className={"mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}/>
                    </div>
                    <div>
                        <label htmlFor={'bounds'} className={"block text-sm font-medium text-gray-700 whitespace-nowrap"}>Bounding box (x/y/z):</label>
                        <input id={"bounds"} type={"text"} readOnly={true} value={display_bounds}  className={"mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}/>
                    </div>
                    <div>
                        <label htmlFor="quantity" className={"block text-sm font-medium text-gray-700"}>
                            Quantity
                        </label>
                        <input id={"quantity"} type={"number"} value={props.part.quantity} onChange={on_change_quantity} min={1} step={1} className={"mt-1 w-24 block py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}/>
                    </div>
                    <div>
                        <label htmlFor={'partcost'} className={"block text-sm font-medium text-gray-700 whitespace-nowrap"}>
                            Cost per part
                        </label>
                        <span className={"absolute ml-3 mt-3 pt-px sm:text-sm"}>$</span>
                        <input id={"partcost"} size={8} type={"number"} step=".01" readOnly={!props.special_pricing} onChange={on_change_part_price} onFocus={highlight_all} value={ props.part.price_per_component } style={{maxWidth: "107px"}} className={"mt-1 block w-full py-2 pl-5 pr-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}/>
                    </div>
                    <div>
                        <label htmlFor="total price" className={"block text-sm font-medium text-gray-700"}>
                            Price
                        </label>
                        <input id={"price"} size={8} type={"text"} readOnly={true} value={"$" + props.part.price_total.toFixed(2)} className={"mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"}/>
                    </div>
                    <div>
                        <button onClick={on_click_testing}>Testing</button>
                    </div>
                    <div className={"flex justify-end flex-grow justify-end mt-6 pt-4 pr-8 h-full color-indigo-500"}>
                        <svg xmlns="http://www.w3.org/2000/svg" onClick={props.onDelete} className="h-6 w-6 stroke-current text-gray-500 hover:text-indigo-500" fill="none" viewBox="0 0 22 22">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </div>
                </div>
                <div className={"pr-6 pl-4 pt-2 pb-2"}>
                    <label className="block">
                        <span className="text-gray-700 whitespace-nowrap">Special Instructions / Comments:  </span>
                        { props.part.material === "Finishing Only" &&
                            <span className="text-red-600 whitespace-nowrap">*Finishing Only Material: Setup fee of $150 may apply.</span> 
                        } 
                        { props.part.material === "174PH SS" &&
                            <span className="text-red-600 whitespace-nowrap">*174PH SS Material: Part evaluation required. May incur additional costs.</span>
                        }
                        <textarea className={"form-textarea mt-1 pl-2 block w-full rounded-md shadow"} rows="3" value={props.part.comments} onChange={on_change_comments}/>
                    </label>
                </div>
                </div>
            </div>
        )
    }
}

const Editor = (props) => {
    return (<Dashview {...props}><Content/></Dashview>);
}

export default Editor;
